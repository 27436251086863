<template>
  <div>
    <h2 class="ui header">
      Pièces jointes
    </h2>
    <div
      v-for="pj in attachments"
      :key="pj.id"
      class="ui divided items"
    >
      <div class="item">
        <a
          class="ui tiny image"
          target="_blank"
          :href="pj.attachment_file"
        >
          <img
            :src="
              pj.extension === '.pdf'
                ? require('@/assets/img/pdf.png')
                : pj.attachment_file
            "
            alt="Pièce jointe au signalement"
          >
        </a>
        <div class="middle aligned content">
          <a
            class="header"
            target="_blank"
            :href="pj.attachment_file"
          >{{
            pj.title
          }}</a>
          <div class="description">
            {{ pj.info }}
          </div>
        </div>
      </div>
    </div>
    <em v-if="attachments.length === 0">
      Aucune pièce jointe associée au signalement.
    </em>
  </div>
</template>

<script>
export default {

  name: 'FeatureAttachements',

  props: {
    attachments: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }

};
</script>
