<template>
  <div class="field">
    <Dropdown
      v-if="!disabled"
      :options="projectMemberOptions"
      :selected="selectedMember ? selectedMember.name : ''"
      :selection.sync="selectedMember"
      :search="true"
      :clearable="true"
    />
    <div v-else-if="selectedMember && selectedMember.name && Array.isArray(selectedMember.name)">
      <span> {{ selectedMember.name[0] || selectedMember.name[1] }}</span>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import { formatUserOption } from '@/utils';
import { mapState } from 'vuex';

export default {
  name: 'ProjectMemberSelect',
    
  components: {
    Dropdown,
  },

  props: {
    selectedUserId: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState('projects', [
      'projectUsers'
    ]),
    projectMemberOptions: function () {
      return this.projectUsers
        .filter((el) => el.level.codename !== 'logged_user') // Filter out user not member of the project (with level lower than contributor)
        .map((el) => formatUserOption(el.user)); // Format user data to fit dropdown option structure
    },
    selectedMember: {
      get() {
        return this.projectMemberOptions.find(el => el.value === this.selectedUserId);
      },
      set(newValue) {
        /**
         * If the user delete previous assigned_member the value is undefined
         * We replace it by null in order to allow empty field to be sent with the request
         * & to comply with UPDATE_FORM_FIELD mutation logic
         * TODO: If refactoring the app one day -> merge together both featureEdit form and feature store form to work the same way
         */
        this.$emit('update:user', newValue.value || null);
      },
    }
  },

  created() {
    this.$store.dispatch('projects/GET_PROJECT_USERS', this.$route.params.slug);
  },
};
</script>