<template>
  <div>
    <table
      class="ui very basic table"
      aria-describedby="Table des données du signalement"
    >
      <tbody>
        <tr v-if="featureType">
          <td>
            <strong> Type de signalement </strong>
          </td>
          <td>
            <FeatureTypeLink :feature-type="featureType" />
          </td>
        </tr>
        <tr
          v-for="field in featureFields"
          :key="field.name"
        >
          <template v-if="!field.isDeactivated">
            <td>
              <strong :class="{ required: field.is_mandatory }">
                {{ field.label }}
              </strong>
            </td>
            <td>
              <strong class="ui form">
                <span
                  v-if="fastEditionMode && canEditFeature && extra_forms.length > 0"
                  :id="field.label"
                >
                  <ExtraForm
                    ref="extraForm"
                    :field="field"
                  />
                </span>
                <i
                  v-else-if="field.field_type === 'boolean'"
                  :class="[
                    'icon',
                    field.value ? 'olive check' : 'grey times',
                  ]"
                  aria-hidden="true"
                />
                <span v-else-if="field.value && field.field_type === 'multi_choices_list'">
                  {{ field.value.join(', ') }}
                </span>
                <span v-else>
                  {{ field.value && field.value.label ? field.value.label : field.value }}
                </span>
              </strong>
            </td>
          </template>
        </tr>
        <tr>
          <td>
            Auteur
          </td>
          <td v-if="currentFeature.properties">
            {{ currentFeature.properties.display_creator }}
          </td>
        </tr>
        <tr>
          <td>
            Statut
          </td>
          <td>
            <i
              v-if="currentFeature.properties && currentFeature.properties.status"
              :class="['icon', statusIcon]"
              aria-hidden="true"
            />
            <FeatureEditStatusField
              v-if="fastEditionMode && canEditFeature && form"
              :status="form.status.value.value || form.status.value"
              class="inline"
            />
            <span v-else>
              {{ statusLabel }}
            </span>
          </td>
        </tr>
        <tr v-if="project && project.feature_assignement">
          <td>
            Membre assigné
          </td>
          <td>
            <ProjectMemberSelect
              :selected-user-id="assignedMemberId"
              :disabled="!fastEditionMode || !canEditFeature"
              class="inline"
              @update:user="$store.commit('feature/UPDATE_FORM_FIELD', { name: 'assigned_member', value: $event })"
            />
          </td>
        </tr>
        <tr>
          <td>
            Date de création
          </td>
          <td v-if="currentFeature.properties && currentFeature.properties.created_on">
            {{ currentFeature.properties.created_on | formatDate }}
          </td>
        </tr>
        <tr>
          <td>
            Date de dernière modification
          </td>
          <td v-if="currentFeature.properties && currentFeature.properties.updated_on">
            {{ currentFeature.properties.updated_on | formatDate }}
          </td>
        </tr>
      </tbody>
    </table>

    <h3>Liaison entre signalements</h3>
    <table
      class="ui very basic table"
      aria-describedby="Table des signalements lié à ce signalement"
    >
      <tbody>
        <tr
          v-for="(link, index) in linked_features"
          :key="link.feature_to.title + index"
        >
          <th
            v-if="link.feature_to.feature_type_slug"
            scope="row"
          >
            {{ link.relation_type_display }}
          </th>
          <td
            v-if="link.feature_to.feature_type_slug"
          >
            <FeatureFetchOffsetRoute
              :feature-id="link.feature_to.feature_id"
              :properties="{
                title: link.feature_to.title,
                feature_type: { slug: link.feature_to.feature_type_slug }
              }"
            />
            ({{ link.feature_to.display_creator }} -
            {{ link.feature_to.created_on }})
          </td>
        </tr>
        <tr v-if="linked_features.length === 0">
          <td>
            <em>
              Aucune liaison associée au signalement.
            </em>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import FeatureTypeLink from '@/components/FeatureType/FeatureTypeLink';
import FeatureEditStatusField from '@/components/Feature/FeatureEditStatusField';
import ExtraForm from '@/components/ExtraForm';
import FeatureFetchOffsetRoute from '@/components/Feature/FeatureFetchOffsetRoute';
import ProjectMemberSelect from '@/components/ProjectMemberSelect';

import { statusChoices, formatStringDate, checkDeactivatedValues } from '@/utils';

export default {

  name: 'FeatureTable',
  
  filters: {
    formatDate(value) {
      return formatStringDate(value);
    },
  },

  components: {
    FeatureTypeLink,
    FeatureEditStatusField,
    ExtraForm,
    FeatureFetchOffsetRoute,
    ProjectMemberSelect,
  },

  props: {
    featureType: {
      type: Object,
      default: () => {},
    },
    fastEditionMode: {
      type: Boolean,
      default: false,
    },
    canEditFeature: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature', [
      'currentFeature',
      'linked_features',
      'form',
      'extra_forms',
    ]),

    statusIcon() {
      switch (this.currentFeature.properties.status.value || this.currentFeature.properties.status) {
      case 'archived':
        return 'grey archive';
      case 'pending':
        return 'teal hourglass outline';
      case 'published':
        return 'olive check';
      case 'draft':
        return 'orange pencil alternate';
      default:
        return '';
      }
    },

    statusLabel() {
      if (this.currentFeature.properties) {
        if (this.currentFeature.properties && this.currentFeature.properties.status.label) {
          return this.currentFeature.properties.status.label;
        }
        const status = statusChoices.find(
          (el) => el.value === this.currentFeature.properties.status
        );
        return status ? status.name : '';
      }
      return '';
    },

    featureData() {
      if (this.currentFeature.properties && this.featureType) {
        // retrieve value for each feature type custom field within feature data
        const extraFieldsWithValue = this.featureType.customfield_set.map((xtraField) => {
          return {
            ...xtraField,
            value: this.currentFeature.properties[xtraField.name]
          };
        });
        // filter out fields not meeting condition to be activated
        return checkDeactivatedValues(extraFieldsWithValue);
      }
      return [];
    },

    featureFields() {
      return this.fastEditionMode ? this.extra_forms : this.featureData;
    },

    assignedMemberId() {
      if (this.form && this.form.assigned_member) {
        return this.form.assigned_member.value;
      }
      return this.currentFeature.properties.assigned_member;
    }
  },
};
</script>

<style lang="less" scoped>

td {
  strong.required:after {
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #ee2e24;
  }
}

</style>
