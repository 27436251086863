var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"ui header"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"two-block"},[(_vm.fastEditionMode && _vm.form && _vm.canEditFeature)?_c('div',{staticClass:"form ui half-block"},[_c('input',{attrs:{"id":"feature_detail_title_input","type":"text","required":"","maxlength":"128","name":"title"},domProps:{"value":_vm.form.title},on:{"blur":_vm.updateTitle}})]):_c('div',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(_vm.currentFeature.properties ? _vm.currentFeature.properties.title : _vm.currentFeature.id)+" ")]),_c('div',{staticClass:"ui icon compact buttons",attrs:{"id":"feature-actions"}},[(_vm.queryparams)?_c('div',{staticClass:"fast_browsing"},[_c('div',[_c('div',[_c('strong',[_vm._v(" Tri en cours: ")]),_c('span',[_vm._v(" par "+_vm._s(_vm.currentSort)+" ")])]),_c('div',[_c('strong',[_vm._v(" Filtre en cours: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.currentFilters)+" ")])])]),_c('span',{staticClass:"feature-count"},[_vm._v(" "+_vm._s(parseInt(_vm.$route.query.offset) + 1)+" sur "+_vm._s(_vm.featuresCount)+" ")]),_c('button',{class:['ui button button-hover-green tiny-margin', { disabled: _vm.queryparams.previous < 0 }],attrs:{"id":"previous-feature","data-tooltip":"Voir le précédent signalement","data-position":"bottom center"},on:{"click":function($event){return _vm.toFeature('previous')}}},[_c('i',{staticClass:"angle left fitted icon",attrs:{"aria-hidden":"true"}})]),_c('button',{class:[
                'ui button button-hover-green tiny-margin',
                { disabled: _vm.queryparams.next >= _vm.featuresCount }
              ],attrs:{"id":"next-feature","data-tooltip":"Voir le prochain signalement","data-position":"bottom center"},on:{"click":function($event){return _vm.toFeature('next')}}},[_c('i',{staticClass:"angle right fitted icon",attrs:{"aria-hidden":"true"}})])]):_vm._e(),_c('div',[(_vm.fastEditionMode && _vm.canEditFeature)?_c('button',{class:['ui button button-hover-orange tiny-margin', { disabled: false }],attrs:{"id":"save-fast-edit","data-tooltip":"Enregistrer les modifications","data-position":"bottom center"},on:{"click":_vm.fastEditFeature}},[_c('i',{staticClass:"save fitted icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.permissions && _vm.permissions.can_create_feature 
                && (_vm.featureType && !_vm.featureType.geom_type.includes('multi')))?_c('router-link',{staticClass:"ui button button-hover-green tiny-margin",attrs:{"id":"add-feature","to":{
                name: 'ajouter-signalement',
                params: {
                  slug_type_signal: _vm.$route.params.slug_type_signal || _vm.featureType ? _vm.featureType.slug : '',
                },
              },"data-tooltip":"Ajouter un signalement","data-position":"bottom center"}},[_c('i',{staticClass:"plus icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.slugSignal && _vm.canEditFeature)?_c('router-link',{staticClass:"ui button button-hover-orange tiny-margin",attrs:{"id":"edit-feature","to":{
                name: 'editer-signalement',
                params: {
                  slug_signal: _vm.slugSignal,
                  slug_type_signal: _vm.$route.params.slug_type_signal || _vm.featureType ? _vm.featureType.slug : '',
                },
                query: _vm.$route.query
              },"data-tooltip":"Éditer le signalement","data-position":"bottom center"}},[_c('i',{staticClass:"inverted grey pencil alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.canDeleteFeature && _vm.isOnline)?_c('a',{staticClass:"ui button button-hover-red tiny-margin",attrs:{"id":"currentFeature-delete","data-tooltip":"Supprimer le signalement","data-position":"bottom right"},on:{"click":function($event){return _vm.$emit('setIsDeleting')}}},[_c('i',{staticClass:"inverted grey trash alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e()],1)])]),_c('div',{staticClass:"sub header prewrap"},[(_vm.fastEditionMode && _vm.canEditFeature && _vm.form)?_c('span',{staticClass:"form ui half-block"},[_c('textarea',{attrs:{"name":"description","rows":"5"},domProps:{"value":_vm.form.description.value},on:{"blur":_vm.updateDescription}})]):(_vm.currentFeature && _vm.currentFeature.properties)?_c('span',[_vm._v(" "+_vm._s(_vm.currentFeature.properties.description)+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }